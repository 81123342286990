.menu {
    padding: 20px 0;
    background-color: $gray-550;
    color: $gray-150;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
    font-size: .8rem;

    &-title {
        text-transform: uppercase;
        margin: 0 1.2rem 15px 1.2rem;
    }

    &-section {
        margin: 0 0 30px 0;
        padding: 0;
        list-style: none;
    }

    &-link {
        display: flex;
        align-items: stretch;
        padding: $nav-link-padding-y 1rem;
        font-weight: 500;
        color: $gray-150;
        
        @include hover-focus {
            text-decoration: none;
          }

        &.active,
        &:hover,
        &[aria-expanded="true"] {
            color: $white;
            background-color: $gray-600;
        }

        &[aria-expanded="true"] {
            border-right: 3px solid $secondary;
        }

        &-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 0 0 30px;
            margin-right: 4px;
            text-align: right;
            font-size: 1.15rem;
            color: $gray-400;
        }

        &-text {
            flex-grow: 1;
        }

        &-right {
            display: flex;
            align-items: center;
            font-size: .8rem;
            color: $gray-400;
        }
    }

    &-submenu {
        border-right: 3px solid $secondary;
        padding: 0;
        margin: 0 0 10px 0;
        list-style: none;

        .active,
        .menu-link:hover {
            background-color: $gray-500;
        }

        .menu-link-icon {
            margin-left: 10px;
            font-size: 0.4rem;
        }
    }
}

