$use-cdn: true !default;

// Variables
//

// Shade generator : https://maketintsandshades.com
$white:    #fff;
$gray-50: #f4f4f4; // TODO vérifier
$gray-100: #EDEDED;
$gray-150: #DCDBDB;
$gray-200: #CAC8C9;
$gray-250: #B9B6B7;
$gray-300: #A7A4A6;
$gray-350: #959294;
$gray-400: #848082;
$gray-450: #726D70;
$gray-500: #615B5E;
$gray-550: #4F494C;
$gray-600: #474244;
$gray-650: #3F3A3D;
$gray-700: #373335;
$gray-750: #2F2C2E;
$gray-800: #282526;
$gray-850: #201D1E;
$gray-900: #181617;
$gray-950: #100F0F;
$black:    #080708;


$blue:    #064393;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #a60000; 
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #6DC70C;
$teal:    #20c997;
$cyan:    #17a2b8;

$colors: (
    'gray':   $gray-500
);

$primary:       #0b6992; //#352b92;
$secondary:     #55c3f1;
/*$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-800;*/

$theme-colors: (
    'gray':   $gray-500,
);


// Body
//

$body-bg: $gray-100;
$body-color: $gray-500;


// Links
//

//$link-color: $secondary;


// Components
//

$border-color:                $gray-150 !default;

$border-radius:               .175rem !default;
$border-radius-lg:            .25rem !default;
$border-radius-sm:            .15rem !default;


// Typography

$font-family-base:            'Poppins' !default;
$font-size-base:              .85rem;
$h1-font-size:                $font-size-base * 1.5;
$h2-font-size:                $font-size-base * 1.4;
$h3-font-size:                $font-size-base * 1.3;
$h4-font-size:                $font-size-base * 1.2;
$h5-font-size:                $font-size-base * 1.1;
$h6-font-size:                $font-size-base;


$text-muted:                  $gray-350;
$blockquote-small-color:      $gray-350;


//$component-active-bg: $beige;


// pagination
//$pagination-color: $link-color;
// Pagination

$pagination-padding-y:              .27rem;
$pagination-padding-x:              .65rem;
$pagination-padding-y-sm:           .25rem;
$pagination-padding-x-sm:           .5rem;
$pagination-padding-y-lg:           .75rem;
$pagination-padding-x-lg:           1.5rem;
$pagination-line-height:            1.25;


// Tables
//

$table-cell-padding:          .65rem !default;
$table-border-color:          $gray-100 !default;
$table-striped-order:         even !default;


// Buttons + Forms
//

$input-btn-padding-y:         .3rem !default;
$input-btn-padding-x:         .55rem !default;


// Forms
//

$input-disabled-bg:                     $gray-100 !default;

$input-color:                           $body-color !default;
$input-border-color:                    $gray-150 !default;

$input-placeholder-color:               $gray-350 !default;


// Pagination
//

$pagination-color:                  $body-color !default;
$pagination-border-color:           $gray-100 !default;

$pagination-hover-color:            $body-color !default;
$pagination-hover-bg:               $gray-150 !default;
$pagination-hover-border-color:     $gray-150 !default;

$pagination-active-bg:              $gray-200 !default;

$pagination-disabled-border-color:  $gray-100 !default;
$pagination-disabled-color:         $gray-400 !default;

// icons
$fa-font-path: "../../node_modules/@fortawesome/fontawesome-free/webfonts";