.box {
    width: 100%;
    margin-bottom: 2rem;
    border-radius: 4px;
    border-top: 3px solid $secondary;
    min-width: 0; // See https://github.com/twbs/bootstrap/pull/22740#issuecomment-305868106
    word-wrap: break-word;
    background-color: $white; 
    box-shadow: 0px 0px 13px 0px rgba(82,63,105,0.05);

    &-header,
    &-footer {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        padding: 1rem 1rem 0.5rem 1rem;

        & > *:first-child {
            flex: 1;
            /*display: flex;
            align-items: center;*/
        }
        
        & > *:last-child {
            display: flex;
            align-items: center;
            align-content: flex-end;
        }
    }

    &-header {
        border-bottom: 1px solid $gray-100;
    }

    &-title {
        
        margin: 0;
        @extend .h5;
    }

    &-toolbar {
    }

    &-body {
        padding: 1.5rem 1rem 1rem 1rem;


    }

    &-footer {
        border-top: 1px solid $gray-100;
    }
}
