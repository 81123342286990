.l-global {
    display: grid;

    height: 100vh;

    grid-template-columns: 250px 1fr auto;

    grid-template-rows: min-content 1fr min-content;

    grid-template-areas: "brand header-a header-b"
                         "menu main main"
                         "menu footer-a footer-b";

    .brand {
        grid-area: brand;
    }

    .menu {
        grid-area: menu;
    }

    .l-global-header-a {
        grid-area: header-a;
    }
    .l-global-header-b {
        grid-area: header-b;
    }

    .main {
        grid-area: main;
    }

    .l-global-footer-a {
        grid-area: footer-a;
    }
    .l-global-footer-b {
        grid-area: footer-b;
    }
}