.form {

    &-label {

        &-required {
            font-size: 0.7rem;
        }
    }
}

