.l-single {
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;


    &-inner {
        width: 100%;
        max-width: 400px;
        padding: 30px;
        margin: 0 auto;
    }
}